<template>
  <q-layout view="hHh LpR fFf" class="bg-white">
    <q-page-container>
      <q-page>
        <div class="row absolute-full">
          <div
            class="col col-md-4 col-lg-5 full-height q-pa-lg text-center"
            style="background-color: #3c1053; color: #fafafa"
          >
            <div class="q-px-xl">
              <img
                :src="require('assets/swift-logo.svg')"
                class="logo q-mb-md cursor-pointer"
                @click="$router.push({ path: '/' })"
              >
              <router-view />
            </div>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: 'LayoutLogin'
}
</script>

<style lang="stylus" scoped>
html {
  background-color: #3c1053;
}

@keyframes carousel {
  0%, 2.5% {
    background-image url('~assets/login/Airport.png')
  }
  10%, 12.5%, 15% {
    background-image url('~assets/login/Bike.png')
  }
  22.5%, 25%, 27.5% {
    background-image url('~assets/login/Bus.png')
  }
  35%, 37.5%, 40% {
    background-image url('~assets/login/BusStop.png')
  }
  47.5%, 50%, 52.5% {
    background-image url('~assets/login/Scooter.png')
  }
  60%, 62.5%, 65% {
    background-image url('~assets/login/Ticket.png')
  }
  72.5%, 75%, 77.5% {
    background-image url('~assets/login/Tram.png')
  }
  85%, 87.5%, 90% {
    background-image url('~assets/login/Tube.png')
  }
  97.5%, 100% {
    background-image url('~assets/login/Airport.png')
  }
}

.q-page
  background-repeat no-repeat
  background-position center center
  background-size cover
  animation carousel 48s infinite ease-out
  &:after
    // This pre-loads the images so they dont flicker
    height 0
    width 0
    z-index -1
    position absolute
    overflow hidden
    content url('~assets/login/Bike.png') url('~assets/login/Bus.png') url('~assets/login/BusStop.png') url('~assets/login/Scooter.png') url('~assets/login/Ticket.png') url('~assets/login/Tram.png') url('~assets/login/Tube.png')

.login-layout {
  min-width: 425px;

  >>> .q-btn.text-white {
    color: #fff !important;
  }
}

.logo {
  width: 70%;
  max-width: 300px;
}
</style>
